import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import logoBlack from "./Logo-black.svg"

export default function Contact({ className }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  return (
    <section
      id="contact"
      className={`${className} text-white font-regular text-base pt-4 print:font-serif print:fixed print:bottom-0 print:w-screen print:h-20 print:text-xs print:pt-0`}
    >
      <div className=" bg-gradient-to-b from-indigo-700 to-gray-900">
        <h1 className="text-center print:hidden">Kontakta mig</h1>

        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 print:flex print:justify-between print:border-t-2 print:pt-2 print:max-w-none">
          <div className="flex flex-col justify-center text-center lg:text-left print:text-left print:block">
            <p className="hidden print:block print:my-0">
              <span>Mikael Lundin</span>
            </p>
            <p className="my-2 print:my-0">
              <span className="hidden lg:inline print:inline">E-post: </span>
              <a
                href="mailto:mikael@klabbet.com"
                nofollow="true"
                className="text-pink-400 print:text-gray-800"
              >
                mikael@klabbet.com
              </a>
            </p>
            <p className="my-2 print:my-0 print:hidden">
              <span className="hidden lg:inline">Bokning: </span>
              <a
                href="https://outlook.office365.com/owa/calendar/Klabbet1@klabbet.com/bookings/"
                className="text-pink-400"
                nofollow="true"
              >
                Boka 45 minuters pratstund
              </a>
            </p>
            <p className="hidden print:block print:my-0">
              <a href={site?.siteMetadata?.siteUrl} nofollow="true">
                {site?.siteMetadata?.siteUrl}
              </a>
            </p>
          </div>

          <img
            className="hidden print:block w-14"
            src={logoBlack}
            alt="Klabbet logo"
          />
          <ul className="flex flex-row text-sm text-center justify-between my-8 mx-4 lg:mx-0 print:hidden">
            {/* <li className="flex flex-col justify-center ">
              <a
                href="https://mikaellundin.name"
                title="Min profilsida"
                className="text-white"
                nofollow="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mx-auto mb-1"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span className="hidden lg:inline">Profil</span>
              </a>
            </li> */}

            <li className="flex flex-col justify-center">
              <a
                href="mailto:mikael@klabbet.com"
                className="text-white"
                title="Skicka ett e-post"
                nofollow="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mx-auto mb-1"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
                <span className="hidden lg:inline">E-post</span>
              </a>
            </li>
            <li className="flex flex-col justify-center">
              <a
                href="https://linkedin.com/in/miklund"
                target="_new"
                nofollow="true"
                className="text-white"
                title="Se min Linkedinprofil"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mx-auto mb-1"
                >
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                  <rect x="2" y="9" width="4" height="12"></rect>
                  <circle cx="4" cy="4" r="2"></circle>
                </svg>
                <span className="hidden lg:inline">LinkedIn</span>
              </a>
            </li>
            <li className="flex flex-col justify-center">
              <a
                href="https://twitter.com/mikaellundin"
                target="_new"
                nofollow="true"
                className="text-white"
                title="Se min twitter feed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mx-auto mb-1"
                >
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                </svg>
                <span className="hidden lg:inline">Twitter</span>
              </a>
            </li>
            <li className="flex flex-col justify-center">
              <a
                href="https://github.com/klabbet"
                target="_new"
                nofollow="true"
                className="text-white"
                title="Klabbet på Github"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mx-auto mb-1"
                >
                  <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                </svg>
                <span className="hidden lg:inline">GitHub</span>
              </a>
            </li>
            <li className="flex flex-col justify-center">
              <a
                href="https://blog.klabbet.com"
                target="_new"
                nofollow="true"
                className="text-white"
                title="Läs min blogg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mx-auto mb-1"
                >
                  <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
                  <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
                </svg>
                <span className="hidden lg:inline">Blogg</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
