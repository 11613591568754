import React from "react"
import getWeek from "date-fns/getWeek"
import getMonth from "date-fns/getMonth"
import startOfWeek from "date-fns/startOfWeek"
import endOfWeek from "date-fns/endOfWeek"
import sub from "date-fns/sub"
import add from "date-fns/add"
import parseISO from "date-fns/parseISO"
import eachDayOfInterval from "date-fns/eachDayOfInterval"
import isWithinInterval from "date-fns/isWithinInterval"
import isWeekend from "date-fns/isWeekend"
import format from "date-fns/format"
import { graphql, useStaticQuery } from "gatsby"
import { sv } from "date-fns/locale"

function startOfISOWeek(date) {
  return startOfWeek(date, {
    locale: sv,
    weekStartsOn: 1,
  })
}

function endOfISOWeek(date) {
  return endOfWeek(date, {
    locale: sv,
    weekStartsOn: 1,
  })
}

function Week({ status, className, children }) {
  if (status === "full") {
    return (
      <li className={`${className} flex flex-row lg:flex-col text-white`}>
        {children}
      </li>
    )
  } else {
    return (
      <li className={`${className} hover:bg-white hover:text-black`}>
        <a href="#contact" className="flex flex-row lg:flex-col text-blue-900">
          {children}
        </a>
      </li>
    )
  }
}

function weekBackgroundColor(utilization) {
  switch (utilization) {
    case "free":
      return "bg-blue-100 text-blue-900"
    case "part":
      return "bg-blue-300 text-blue-900"
    case "full":
      return "bg-blue-500"
    default:
      return ""
  }
}

function Utilization({ week, className }) {
  switch (week.status) {
    case "free":
      return (
        <div className={className}>
          Obokad
          <br />
          Kontakta mig
        </div>
      )
    case "full":
      return <div className={className}>Fullbokad</div>
    case "part":
      return (
        <div className={className}>
          Delvis bokad
          <br />
          {week.utilization}%
        </div>
      )
    default:
      return <div className={className}></div>
  }
}

function getUtilization(calendar) {
  return date => {
    // check if date is within a calendar event
    const isBusy = calendar.some(
      interval => isWithinInterval(date, interval) || isWeekend(date)
    )
    // get the week number of date (zero based index)
    const weekNumber = getWeek(date, {
      locale: sv,
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    })
    // get the month of date
    const month = getMonth(date)

    return {
      date,
      isBusy,
      weekNumber,
      month,
    }
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

function getMonthName(month) {
  return format(new Date(2021, 1, 1).setMonth(month), "LLLL", { locale: sv })
}

function getMonthAbbr(month) {
  return format(new Date(2021, 1, 1).setMonth(month), "LLL", { locale: sv })
}

function makeUTCDate(date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

function createCalendarFromIcal(queryData) {
  const { allIcal } = queryData
  const { edges } = allIcal

  return edges.map(edge => {
    const { node } = edge

    return {
      // start should be inclusive
      start: parseISO(node.start),
      // end should be exlusive, if another time is starting at "end" it should not be counted as busy
      end: sub(parseISO(node.end), { seconds: 1 }),
    }
  })
}

export default function Calendar({ className }) {
  // how many weeks to display on one row
  const columns = 6

  // number of rows to display
  const rows = 5

  const data = useStaticQuery(graphql`
    {
      allIcal {
        edges {
          node {
            start
            end
            summary
          }
        }
      }
    }
  `)

  // construct an array of { start, end } dates from ical data
  const calendar = createCalendarFromIcal(data)

  // include this week in the calendar
  const start = startOfISOWeek(makeUTCDate(new Date()))
  // generate 7 days * 6 columns * 5 rows = 210 days
  const end = add(start, { days: 7 * columns * rows })
  // get all dates in the time span
  const dates = eachDayOfInterval({ start, end }).map(makeUTCDate)
  // iterate over the dates in the 6 month interval
  const annotatedDates = dates.map(getUtilization(calendar))
  // create a viewmodel
  let viewModel = []
  // iterate over rows
  for (let i = 0; i < rows; i += 1) {
    const startPeriod = add(start, { weeks: i * columns })
    const endPeriod = sub(add(start, { weeks: (i + 1) * columns }), {
      seconds: 1,
    })
    // get dates within this batch of weeks
    const batchOfDays = annotatedDates.filter(annotatedDate =>
      isWithinInterval(annotatedDate.date, {
        start: startPeriod,
        end: endPeriod,
      })
    )

    // find what months are included in the next batch of weeks
    const months = batchOfDays.map(date => date.month).filter(onlyUnique)

    // get the number of days included in each month
    const annotatedMonths = months.map(month => ({
      month,
      days: batchOfDays.filter(day => day.month === month).length,
      name: getMonthName(month),
      abbr: getMonthAbbr(month),
    }))

    // get utilization of each week
    const weeks = batchOfDays.map(date => date.weekNumber).filter(onlyUnique)

    // get the utilization of each week
    const annotatedWeeks = weeks.map(weekNumber => {
      // calculate the % utilization for each week
      const utilization =
        (batchOfDays.filter(day => day.weekNumber === weekNumber && day.isBusy)
          .length -
          2) * // remove the weekend
        20
      // create a status of the utilization
      const status =
        utilization === 0 ? "free" : utilization >= 100 ? "full" : "part"

      // get start date of week
      const startDate = startOfISOWeek(
        batchOfDays.find(day => day.weekNumber === weekNumber).date
      )
      // get end date of week
      const endDate = endOfISOWeek(startDate)

      return {
        weekNumber,
        utilization,
        status,
        startDate,
        endDate,
      }
    })

    viewModel.push({
      months: annotatedMonths,
      weeks: annotatedWeeks,
    })
  }

  return (
    <section id="availability" className={`${className} print:hidden`}>
      <div className="  p-4 lg:px-0">
        <h1 className="text-white text-center">Tillgänglighets&shy;kalender</h1>
        <p className="text-white text-center">
          Kalendern visar när jag är tillgänglig för nya uppdrag.
        </p>
        <div className="container mx-auto font-regular text-base text-center">
          {viewModel.map((row, i) => (
            <div
              key={i}
              className="border-r-2 lg:border-r-0 border-t-2 border-l-2 lg:border-b-2 border-blue-900 mb-4 shadow-xl flex flex-row lg:block"
            >
              <ul className="flex flex-col lg:flex-row m-0 list-none border-r-2 lg:border-r-0 border-blue-900">
                {row.months.map(month => (
                  <li
                    key={month.month}
                    style={{ flex: month.days }}
                    className="bg-blue-200 text-blue-900 mb-0 font-bold border-b-2 lg:border-b-0 lg:border-r-2 border-blue-900 p-4 lg:p-0 flex lg:block items-center lg:justify-center w-16 lg:w-auto"
                  >
                    <p className="sideways text-center overflow-clip lg:m-0">
                      <abbr title={month.name}>{month.abbr}</abbr>
                    </p>
                  </li>
                ))}
              </ul>
              <ul className="flex flex-col lg:flex-row flex-1 lg:flex-none list-none lg:border-t-2 border-blue-900">
                {row.weeks.map(week => (
                  <Week
                    key={week.weekNumber}
                    status={week.status}
                    className={`${weekBackgroundColor(
                      week.status
                    )} flex-1  h-20 border-b-2 lg:border-b-0 lg:border-r-2 border-blue-900`}
                  >
                    <div className="flex flex-col flex-1 lg:flex-row lg:flex-initial font-regular h-20 lg:h-auto">
                      <span className="flex-1 text-xs text-left ml-1 mt-1 lg:mt-0">
                        {format(week.startDate, "do", { locale: sv })}
                      </span>
                      <span className="flex-grow flex items-center lg:justify-center text-left lg:text-center ml-1 lg:ml-0">
                        v{week.weekNumber}
                      </span>
                      <span className="flex-1 text-xs text-left lg:text-right ml-1 mr-1 mb-1">
                        {format(week.endDate, "do", { locale: sv })}
                      </span>
                    </div>
                    <Utilization
                      week={week}
                      className="flex flex-grow lg:flex-1 items-center justify-center text-sm"
                    />
                  </Week>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <p className="text-white text-center">
          Kalendern uppdaterades{" "}
          <span>{format(new Date(), "PPP", { locale: sv })}</span>
        </p>
      </div>
    </section>
  )
}
