import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import CodeIcon from "../icons/codeIcon"
import ProductIcon from "../icons/productIcon"
import ArchitectIcon from "../icons/architectIcon"
import ProjectIcon from "../icons/projectIcon"

function Icon({ type, className }) {
  switch (type) {
    case "code":
      return <CodeIcon color="white" className={className} />
    case "architect":
      return <ArchitectIcon color="white" className={className} />
    case "project":
      return <ProjectIcon color="white" className={className} />
    case "product":
      return <ProductIcon color="white" className={className} />
    default:
      return <></>
  }
}

export default function Services({ className }) {
  const data = useStaticQuery(graphql`
    query Service {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/services//" } }
        sort: { fields: fileAbsolutePath }
      ) {
        edges {
          node {
            html
            frontmatter {
              title
              icon
              tags
            }
          }
        }
      }
    }
  `)
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  return (
    <section
      id="services"
      className={`${className} text-white py-4 print:always-page-break-before always-page-break-after`}
    >
      <h1 className="text-center print:text-left">Tjänster</h1>
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 mx-8 p-4 lg:px-0 print:mx-0 print:p-0 print:block print:max-w-none">
        {edges.map((edge, i) => (
          <article
            key={i}
            className="bg-indigo-800 p-8 border-2 border-purple-700 rounded-lg shadow-xl print:border-0 print:p-0 print:shadow-none avoid-page-break-after print:clear-left"
          >
            <h2 className={`leading-8 ${i > 0 ? "print:pt-8" : ""}`}>
              <Icon
                type={edge?.node?.frontmatter?.icon}
                className="w-8 h-8 float-left mr-4"
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: edge?.node?.frontmatter?.title,
                }}
              ></span>
            </h2>
            <div dangerouslySetInnerHTML={{ __html: edge.node.html }}></div>
            <h3>Jag kan hjälpa er med</h3>
            <ul>
              {(edge?.node?.frontmatter?.tags ?? []).map((tag, j) => (
                <li
                  key={j}
                  className="bg-purple-400 border border-purple-700 py-2 px-4 float-left font-regular text-sm mr-4 my-2 rounded-lg print:border-2 print:border-gray-800 print:py-1 print:px-2 print:text-xs print:mr-2 print:my-1"
                >
                  {tag}
                </li>
              ))}
            </ul>
          </article>
        ))}
      </div>
    </section>
  )
}
