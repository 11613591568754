import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"
import Services from "../components/services"
import Calendar from "../components/calendar"
import Contact from "../components/contact"
import Experience from "../components/experience"

import "../styles/dividers.scss"

/*
     <!-- Portfolio -->
     <!-- Certifications -->
       <Rates />
*/

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Mikael Lundin" />
      <Bio className="section-bubble1" />
      <Services className="section-bubble2" />
      <Experience className="section-bubble3" />
      <Calendar className="section-bubble4" />
      <Contact className="bg-indigo-700" />
    </Layout>
  )
}

export default IndexPage
